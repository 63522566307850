<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:viewBox="viewBox"
		:width="width"
		:height="height"
		class="monitor-selector"
		:style="maxWidth"
	>
		<g v-for="(monitor, idx) in monitors" :key="monitor.id">
			<rect
				:x="monitor.bounds.x + padding"
				:y="monitor.bounds.y + padding"
				:width="monitor.size.width - padding * 2"
				:height="monitor.size.height - padding * 2"
				class="monitor"
				:class="{
					selected: monitor.id === selectedMonitorId,
					primary: monitor.id === primaryMonitorId,
				}"
				@click.stop="$emit('select-monitor', monitor.id)"
			/>
			<!-- Mac style -->
			<rect
				v-if="monitor.id === primaryMonitorId && isMac"
				:x="monitor.bounds.x + padding"
				:y="monitor.bounds.y + padding"
				:width="monitor.size.width - padding * 2"
				class="primary nouser"
				height="70"
			/>
			<!-- Windows Style -->
			<rect
				v-if="monitor.id === primaryMonitorId && isWindows"
				:x="monitor.bounds.x + padding"
				:y="monitor.bounds.y + monitor.size.height - padding - 100"
				:width="monitor.size.width - padding * 2"
				class="primary nouser"
				height="100"
			/>
			<rect
				:x="monitor.bounds.x + padding"
				:y="monitor.bounds.y + padding"
				:width="monitor.size.width - padding * 2"
				:height="monitor.size.height - padding * 2"
				class="stroke"
			/>
			<text
				:x="monitor.bounds.x + monitor.size.width / 2"
				:y="monitor.bounds.y + monitor.size.height / 2 + padding"
				:font-size="monitor.size.height * 0.5"
				:dominant-baseline="monitorTextMap[monitor.id] ? null : 'middle'"
				text-anchor="middle"
				class="nouser number"
			>
				{{ idx + 1 }}
			</text>
			<text
				:x="monitor.bounds.x + monitor.size.width / 2"
				:y="monitor.bounds.y + monitor.size.height * 0.8"
				:font-size="monitor.size.height * 0.09"
				dominant-baseline="bottom"
				text-anchor="middle"
				class="nouser"
			>
				{{ monitorTextMap[monitor.id] }}
			</text>
		</g>
	</svg>
</template>

<script>
const is = window.ElectronInterface.is || {
	windows() {
		return false
	},
	macOS() {
		return false
	},
}

export default {
	name: 'MonitorLayout',
	props: {
		monitors: {
			type: Array,
			required: true,
		},
		primaryMonitorId: {
			type: [String, Number],
			required: true,
		},
		selectedMonitorId: {
			type: [String, Number],
			default: '',
		},
		width: {
			type: [Number, String, null],
			default: '100%',
		},
		height: {
			type: [Number, String, null],
			default: null,
		},
		padding: {
			type: Number,
			default: 40,
		},
		monitorTextMap: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		viewBox() {
			let [minx, miny, maxx, maxy] = [0, 0, 0, 0]
			this.monitors.forEach(({ bounds: { x, y, width, height }, id }) => {
				if (x < minx) minx = x
				if (y < miny) miny = y
				if (x + width > maxx) maxx = x + width
				if (y + height > maxy) maxy = y + height
			})
			return [minx, miny, maxx - minx, maxy - miny].join(' ')
		},
		maxWidth() {
			return `max-width: ${this.monitors.length * 200 + 200}px`
		},
	},
	created() {
		this.isWindows = is.windows()
		this.isMac = is.macOS()
	},
}
</script>

<style lang="scss" scoped>
svg.monitor-selector {
	.stroke {
		stroke-width: 2;
		stroke: var(--toolbar-button-color-active);
		// Ignore warning, it's good
		vector-effect: non-scaling-stroke;
		fill: none;
	}
	rect {
		&.monitor {
			opacity: 0.3;
			fill: var(--primary-label);
		}
		&.selected {
			opacity: 1;
			fill: var(--button-primary-bg-hover);
			& ~ .stroke {
				stroke: var(--primary-badge-bg);
			}
		}
	}
	text {
		fill: var(--primary-label);
	}
	.nouser {
		pointer-events: none;
		user-select: none;
	}
	.primary {
		fill: #e2e8f0;
	}
}
</style>
