<template>
	<div class="monitor-selector">
		<h4 style="margin-bottom: 12px;">Monitors</h4>
		<monitor-layout
			:monitors="monitors"
			:primary-monitor-id="primaryMonitorId"
			:selected-monitor-id="selectedMonitor"
			:monitor-text-map="monitorTextMap"
			@select-monitor="onMonitorSelected"
		/>
		<p v-if="monitors.length > 1" style="text-align: center;">
			Click on a monitor to configure it.
			<br />
			<a href="#identify-monitors" @click.prevent="showMonitorHelpers">
				Identify monitors
			</a>
		</p>

		<div style="display:inline-flex; flex-direction: row; margin: 24px 0 12px">
			<div>
				<monitor-settings-table
					:disabled="!selectedMonitor"
					:value="monitorSettings[selectedMonitor]"
					@change="onMonitorSettingChanged($event)"
					@click.native.stop=""
				/>
			</div>
			<div style="display: flex; flex-direction: column; justify-content: space-around; margin-left: 24px">
				<ast-button :disabled="!selectedMonitor" @click="resetMonitor()">
					Reset Monitor {{ selectedMonitorIndex }}
				</ast-button>
			</div>
		</div>
		<div style="display: flex; align-items: center;">
			<label style="margin-right: 16px">Default Layout for Monitor {{ selectedMonitorIndex }}</label>
			<ast-button-dropdown type="primary" :disabled="!canSetLayout" style="min-width:90px" @click.native.stop="">
				<template #button>
					<span v-html="selectedMonitorViewerLayout"></span>
				</template>
				<template #dropdown="{hide}">
					<ast-toolbar-menu v-if="selectedMonitor" class="layout-menu" @click.native.stop="">
						<checkbox
							:value="isDefaultActiveMonitorLayout"
							style="padding-right: 0"
							@input="
								() => {
									saveLayoutPreset({ r: 0, c: 0 })
									hide()
								}
							"
						>
							Use Default
						</checkbox>
						<layout-grid-selector :value="activeMonitorLayoutPreset" @input="saveLayoutPreset($event)" />
					</ast-toolbar-menu>
				</template>
			</ast-button-dropdown>
		</div>
	</div>
</template>

<script>
import MonitorLayout from './MonitorLayout.vue'
import MonitorSettingsTable from './MonitorSettingsTable.vue'
import AstButton from '@components/Button'
import LayoutGridSelector from '@components/layout/LayoutGridSelector.vue'
import Checkbox from '@components/Checkbox.vue'
import AstButtonDropdown from '@components/ButtonDropdown.vue'
import AstToolbarMenu from '@components/ToolbarMenu.vue'

import { omniDesktop } from './omniDesktop'

const settingsTextMap = {
	viewer: 'Viewer',
	primaryViewer: 'Primary Viewer',
	worklist: 'Worklist',
}

const defaultMonitorSettings = {
	viewer: false,
	primaryViewer: false,
	worklist: false,
	layout: { r: 0, c: 0 },
}

export default {
	name: 'MonitorSelector',
	components: {
		MonitorLayout,
		MonitorSettingsTable,
		AstButton,
		LayoutGridSelector,
		Checkbox,
		AstButtonDropdown,
		AstToolbarMenu,
	},
	data() {
		return {
			primaryMonitorId: '',
			monitors: [],
			selectedMonitor: '',
			monitorSettings: {},
		}
	},
	computed: {
		monitorLayoutHash() {
			return this.monitors
				.map(monitor => monitor.id)
				.sort()
				.join('-')
		},
		monitorTextMap() {
			return Object.entries(this.monitorSettings).reduce((prev, [monitorId, settings]) => {
				prev[monitorId] = Object.entries(settings)
					.filter(([key, value]) => value && key !== 'layout')
					.map(([key, value]) => (value ? settingsTextMap[key] : ''))
					.join(', ')
				return prev
			}, {})
		},
		selectedMonitorViewerLayout() {
			if (!this.selectedMonitor) return 'Default'
			if (!this.isDefaultActiveMonitorLayout) {
				const { layout } = this.monitorSettings[this.selectedMonitor]
				return `${layout.r} &times; ${layout.c}`
			}
			return 'Default'
		},
		selectedMonitorIndex() {
			if (!this.selectedMonitor) return 0
			return this.monitors.findIndex(({ id }) => id === this.selectedMonitor) + 1
		},
		isDefaultActiveMonitorLayout() {
			const { r, c } = this.activeMonitorLayoutPreset
			return r === 0 && c === 0
		},
		activeMonitorLayoutPreset() {
			return this.monitorSettings[this.selectedMonitor].layout || { r: 0, c: 0 }
		},
		canSetLayout() {
			return (
				this.selectedMonitor &&
				(this.monitorSettings[this.selectedMonitor].viewer || this.monitorSettings[this.selectedMonitor].primaryViewer)
			)
		},
	},
	watch: {
		monitorLayoutHash() {
			this.getMonitorLayoutSettings()
		},
	},
	mounted() {
		this.refreshMonitors()
		omniDesktop.screen.on('display-removed', this.refreshMonitors)
		omniDesktop.screen.on('display-added', this.refreshMonitors)
		omniDesktop.screen.on('display-metrics-changed', this.refreshMonitors)
	},
	beforeDestroy() {
		omniDesktop.screen.off('display-removed', this.refreshMonitors)
		omniDesktop.screen.off('display-added', this.refreshMonitors)
		omniDesktop.screen.off('display-metrics-changed', this.refreshMonitors)
	},
	methods: {
		onMonitorSelected(monitorId) {
			if (this.selectedMonitor === monitorId || !monitorId) return
			this.selectedMonitor = monitorId
			if (!this.monitorSettings[monitorId]) {
				this.$set(this.monitorSettings, monitorId, { ...defaultMonitorSettings })
			}
		},
		onMonitorSettingChanged(monitorSetting) {
			if (this.selectedMonitor) {
				// Reset layout to default if not a viewer monitor
				if (!monitorSetting.viewer && !monitorSetting.primaryViewer) {
					monitorSetting.layout = { ...defaultMonitorSettings.layout }
				}
				const tempSettings = { ...this.monitorSettings }
				tempSettings[this.selectedMonitor] = monitorSetting
				Object.keys(this.monitorSettings)
					// Intentionally use loose equals, once id is used as key, key becomes string
					// eslint-disable-next-line eqeqeq
					.filter(mid => mid != this.selectedMonitor)
					.forEach(mid => {
						if (monitorSetting.primaryViewer) tempSettings[mid].primaryViewer = false
						if (monitorSetting.worklist) tempSettings[mid].worklist = false
					})
				this.monitorSettings = tempSettings
				omniDesktop.request('setMonitorLayoutSettings', {
					hash: this.monitorLayoutHash,
					settings: tempSettings,
				})
			}
		},
		refreshMonitors() {
			this.primaryMonitorId = omniDesktop.screen.getPrimaryDisplay().id
			this.monitors = omniDesktop.screen.getAllDisplays().sort((a, b) => a.bounds.x - b.bounds.x)
			this.$nextTick(async () => {
				await this.getMonitorLayoutSettings()
				const monitorForThisWindow = await omniDesktop.request('getMonitorForWindow')
				// if selected monitor either has not been set, or was disconnected, select the monitor we are on
				if (this.monitors.length && this.selectedMonitorIndex < 1) {
					this.onMonitorSelected(monitorForThisWindow ? monitorForThisWindow.id : this.monitors[0].id)
				}
			})
		},
		async getMonitorLayoutSettings() {
			this.monitorSettings = await omniDesktop.request('getMonitorLayoutSettings', {
				hash: this.monitorLayoutHash,
			})
		},
		showMonitorHelpers() {
			omniDesktop.request('showMonitorHelpers')
		},
		resetMonitor() {
			this.onMonitorSettingChanged({ ...defaultMonitorSettings })
		},
		saveLayoutPreset(layout) {
			const monitorSetting = { ...this.monitorSettings[this.selectedMonitor], layout }
			this.onMonitorSettingChanged(monitorSetting)
		},
	},
}
</script>

<style scoped>
div.monitor-selector {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.layout-menu {
	flex-direction: column;
	align-items: center;
	padding: 8px;
}
</style>
