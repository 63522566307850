<template>
	<div class="range">
		<span v-if="percent > 0" class="range-value" :style="`width:${percent}%`"></span>
	</div>
</template>

<script>
export default {
	props: {
		percent: {
			type: Number,
			default: 0,
		},
	},
}
</script>

<style lang="scss" scoped>
.range {
	position: relative;
	width: 100%;
	height: 8px;
	margin: 0 1rem;
	background-color: var(--range-indicator-bg);
	border-radius: 9999px;
	overflow: hidden;
	.range-value {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		padding: 0;
		margin: 0;
		background-color: var(--range-indicator-value-bg);
		border: 1px solid var(--range-indicator-value-border);
	}
}
</style>
