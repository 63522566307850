<template>
	<ul>
		<li><checkbox v-model="worklist" :disabled="disabled">Worklist</checkbox></li>
		<li><checkbox v-model="viewer" :disabled="disabled">Viewer</checkbox></li>
		<li>
			<checkbox v-model="primaryViewer" :disabled="disabled">Primary Viewer</checkbox>
		</li>
	</ul>
</template>

<script>
import Checkbox from '@components/Checkbox.vue'

export default {
	components: { Checkbox },
	model: {
		prop: 'value',
		event: 'change',
	},
	props: {
		value: {
			type: Object,
			default: () => ({
				viewer: false,
				primaryViewer: false,
				worklist: false,
			}),
		},
		disabled: Boolean,
	},
	computed: {
		viewer: {
			get() {
				return this.value.viewer
			},
			set(newVal) {
				const obj = { ...this.value, viewer: newVal }
				this.$emit('change', obj)
			},
		},
		primaryViewer: {
			get() {
				return this.value.primaryViewer
			},
			set(newVal) {
				const obj = { ...this.value, primaryViewer: newVal }
				this.$emit('change', obj)
			},
		},
		worklist: {
			get() {
				return this.value.worklist
			},
			set(newVal) {
				const obj = { ...this.value, worklist: newVal }
				this.$emit('change', obj)
			},
		},
	},
}
</script>

<style scoped>
li {
	display: block;
	padding: 0;
	max-width: 300px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
label.control {
	margin: 0px;
}
</style>
