<template>
	<div class="viewer-image-cache">
		<strong>Viewer Image Cache</strong>
		<ul class="list" style="margin-top: 1rem">
			<li>
				<label for="cache-images">
					Cache images to local file system
					<span class="summary">Improves image loading performance for multiple monitors and windows</span>
				</label>

				<toggle
					id="cache-images"
					:disabled="imageCache.isLoading"
					:checked="imageCache.isActive"
					@change="imageCache.isActive = $event"
				/>
			</li>
			<template v-if="imageCache.isActive">
				<li>
					<label for="precache-images">
						Precache images for recently uploaded studies
						<span class="summary"
							>Downloads and saves images in the background for studies uploaded <strong>today</strong></span
						>
					</label>

					<toggle
						id="precache-images"
						:disabled="imageCache.isLoading"
						:checked="imageCache.isPrecacheActive"
						@change="imageCache.isPrecacheActive = $event"
					/>
				</li>
				<li>
					<label style="display:flex; align-items:center">
						<span style="width: 95px">Cache folder:</span>
						<span class="summary" style="margin-left: 8px">{{ imageCache.directoryPath }}</span>
					</label>
					<button class="btn btn-default" :disabled="imageCache.isLoading" @click="imageCache.browseDirectory">
						<svg-icon icon="magnify" />
						Browse...</button
					>
				</li>
				<li>
					<label style="display:flex; align-items:center;flex-shrink:0">
						<span style="width: 95px">Cache usage:</span>
						<span class="summary">{{ imageCache.size | fileSize }}</span>
						<span class="summary" style="margin-left:0.5rem"
							>({{ imageCacheUsage }}% of {{ imageCacheAllocatedSize | fileSize }})</span
						>
					</label>
					<range-indicator :percent="imageCacheUsage" />
					<button class="btn btn-default" :disabled="clearImageCacheDisabled" @click="imageCache.clear">
						<svg-icon icon="delete" />
						Clear</button
					>
				</li>
				<li style="justify-content:start">
					<label style="flex-grow:0">
						<span style="width: 95px">Cache limit:</span>
					</label>
					<input
						v-model.number="imageCacheAllocatedGB"
						class="input"
						style="flex-grow:0;width:80px;margin-left:8px"
						type="number"
						min="0.5"
						step="0.5"
						:disabled="imageCache.isLoading"
					/>
					<label style="padding:0"><span class="summary">GB</span></label>
				</li>
			</template>
		</ul>
	</div>
</template>

<script>
import imageCache from '@/imageLoader/imageCache'
import Toggle from '@components/Toggle.vue'
import RangeIndicator from '@components/RangeIndicator.vue'

export default {
	components: {
		Toggle,
		RangeIndicator,
	},
	data() {
		return {
			imageCache,
			imageCacheAllocatedSize: 0,
		}
	},
	computed: {
		imageCacheUsage() {
			const allocatedSize = this.imageCacheAllocatedSize
			const { size } = this.imageCache
			return allocatedSize >= 0 ? Math.round((size / allocatedSize) * 100) : 0
		},
		imageCacheAllocatedGB: {
			get() {
				return this.imageCacheAllocatedSize / Math.pow(1024, 3)
			},
			set(val) {
				this.imageCacheAllocatedSize = Number.parseFloat(val) * Math.pow(1024, 3)
			},
		},
		clearImageCacheDisabled() {
			return imageCache.isLoading || imageCache.size <= 0
		},
	},
	created() {
		imageCache.refreshMeta()
		this.refreshMetaInterval = setInterval(() => imageCache.refreshMeta(), 1000)
		this.imageCacheAllocatedSize = imageCache.allocatedSize
	},
	beforeDestroy() {
		clearInterval(this.refreshMetaInterval)
		imageCache.allocatedSize = this.imageCacheAllocatedSize
	},
}
</script>

<style lang="scss" scoped>
ul.list {
	list-style: none;
	background-color: var(--secondary-bg);

	li {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 8px;
		&:not(:last-child) {
			border-bottom: 1px solid var(--table-row-border);
		}
		> label {
			// Mimic table row height
			padding: 13px 8px;
			flex-grow: 1;
			.summary {
				font-size: small;
				font-style: italic;
				display: block;
				margin: 3px 0 0 8px;
				opacity: 0.8;
			}
		}
		button {
			width: 100px;
			flex-shrink: 0;
		}
	}
}
</style>
