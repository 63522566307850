<template>
	<div class="electron-settings">
		<div style="margin-bottom:1em;" :class="{ warning: !openStudiesInNewWindow }">
			These settings will only be effective if "Open Studies In New Window" in Viewer Configuration is enabled
		</div>
		<span v-if="monitors.length === 0">
			No monitors were detected. This can happen if you are running Omni from a remote or virtual session.
		</span>
		<template v-else>
			<monitor-selector />
			<div style="font-size: smaller; font-style:italic; margin-top: 8px; text-align: center;">
				<p>The viewer window will use this layout if the Modality specific layout setting is set to "Default".</p>
				<a href="#" @click="onOpenViewerConfig">Edit Modality specific Layouts</a>
			</div>
		</template>
		<viewer-image-cache style="margin-top: 1rem" />
	</div>
</template>

<script>
import MonitorSelector from '@/electron/MonitorSelector.vue'
import ViewerImageCache from '@/electron/ViewerImageCache.vue'
import { omniDesktop } from '@/electron/omniDesktop'

export default {
	name: 'Workstation',
	components: { MonitorSelector, ViewerImageCache },
	data() {
		return {}
	},
	computed: {
		openStudiesInNewWindow() {
			return this.$store.state.windows.openStudiesInNewWindow
		},
		monitors() {
			if (omniDesktop.isConnected) return omniDesktop.screen.getAllDisplays()
			else {
				console.log('electron not accessible')
				return []
			}
		},
	},
	methods: {
		onOpenViewerConfig() {
			this.$emit('switch-view', 'viewer-configuration', { initialTab: 'layout' })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.warning {
	color: var(--icon-danger);
}
</style>
